import Wrapper from './components/Wrapper';

import './App.css';

function App() {
  return (
    <Wrapper />
  );
}

export default App;
